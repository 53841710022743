<template lang="">
    <div>
		<v-card>
			<v-container fluid>

				<v-row><v-col cols="12"></v-col></v-row>
			</v-container>
        </v-card>
    </div>
</template>
<script>
import Breadcrumbs from "@components/Breadcrumbs";
import JsonEditor from "@components/ComponentJsonEditor";
export default {
    components: { Breadcrumbs, JsonEditor },
    data () {
        return {

        };
    },
    methods: {

    },
    mounted () {

    }
};
</script>
<style lang="">

</style>
